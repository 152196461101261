import { FlightDeal } from '#types/flight';
import { useNavigation, useRuntimeConfig, useTracker, useDevice } from '#imports';

export const useFlightRecommendation = () => {
  const { isCrawler } = useDevice();
  const { navigationPath } = useNavigation();

  function showFlightResult(flight: FlightDeal) {
    const { depDate, depAirport, arrAirport, depAirportCity, arrAirportCity } = flight;

    if (isCrawler) {
      return navigationPath(
        '/flight-tickets/' + normalizeSlug(`${depAirportCity}-${depAirport}-${arrAirportCity}-${arrAirport}`, true),
      );
    } else {
      return navigationPath({
        path: '/flight/search',
        query: {
          depDate,
          depAirport,
          arrAirport,
          adult: 1,
          cabin: 'economy',
        },
      });
    }
  }

  function cityImage(arrAirportCity: string) {
    const cityName = arrAirportCity?.split(' ').join('-').toLowerCase();

    if (!cityName) {
      return '';
    }

    return `${useRuntimeConfig().public.cdnAssetUrl}/cities/320x180/${cityName}.png`;
  }

  function trackFlightPopular(flight: FlightDeal) {
    useTracker().ga(
      'general_choose_recommendation',
      'general_choose_recommendation',
      `${flight.depAirport}_${flight.arrAirport}`,
    );

    useTracker().jitsuEvent('user-click-placeholder', {
      event_name: 'recommendedflight-click',
      product: 'flight',
      object_name: 'exclusive-recommendation',
      object_parameter: `${flight.depAirport}${flight.arrAirport}`,
    });

    useTracker().gtagEvent('recommendedflight-click', {});
  }

  return {
    showFlightResult,
    cityImage,
    trackFlightPopular,
  };
};
